<template>
  <section class="config-selector main-body">
    <self-help location="app" v-if="!modules.default.LFCExclusive" />

    <ul class="config-selector__list">
      <li class="config-selector__item" v-if="modules.default.AppEcommerceManager">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Ecommerce Links</h2>
        </div>
        <v-btn :to="'/app-management/ecommerce-links'">Edit</v-btn>
      </li>
      <li class="config-selector__item" v-if="modules.default.AppTicketingManager">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Ticketing Links</h2>
        </div>
        <v-btn :to="'/app-management/ticketing-links'">Edit</v-btn>
      </li>
      <li class="config-selector__item" v-if="modules.default.AppSocialManager">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Social Media Links</h2>
        </div>
        <v-btn :to="'/app-management/social-links'">Edit</v-btn>
      </li>
      <li class="config-selector__item" v-if="modules.default.RewardsManager">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">Rewards Manager</h2>
        </div>
        <v-btn :to="'/app-management/rewards-manager'">Edit</v-btn>
      </li>
      <li class="config-selector__item" v-if="defaultSettings.default.platformSettings.showApp">
        <div class="config-selector__inner">
          <h2 class="config-selector__subheading subheading">App Configuration</h2>
        </div>
        <v-btn :to="'/app-management/app-configuration'">Edit</v-btn>
      </li>
    </ul>
  </section>
</template>

<script>
import SelfHelp from "@/components/Library/SelfHelp"

export default {
  name: "AppDashboard",

  components: {
    SelfHelp
  },

  data: () => ({
    modules: null,
  }),

  created() {
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  }
}
</script>

<style scoped lang="scss">
  .config-selector {
    &__list {
      padding: 0;
      margin: 20px 0 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 16px;
      padding: 20px;
      border: 1px solid #67247c;
      border-radius: 6px;
    }

    &__subheading {
      margin: 0;
      padding: 5px 0 0;
    }
  }
</style>
